//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * VerifyPoints
 * @description 點選
 * */
import { resetSize } from './../utils/util'
import { aesEncrypt } from './../utils/ase'
import { reqGet, reqCheck } from '@/api/modular/system/loginManage'

export default {
    name: 'VerifyPoints',
    props: {
        // 彈出式pop，固定fixed
        mode: {
            type: String,
            default: 'fixed'
        },
        // eslint-disable-next-line vue/require-default-prop
        captchaType: {
            type: String
        },
        // 間隔
        vSpace: {
            type: Number,
            default: 5
        },
        imgSize: {
            type: Object,
            default() {
                return {
                    width: '310px',
                    height: '155px'
                }
            }
        },
        barSize: {
            type: Object,
            default() {
                return {
                    width: '310px',
                    height: '40px'
                }
            }
        }
    },
    data() {
        return {
            secretKey: '', // 後端返回的ase加密秘鑰
            checkNum: 3, // 默認需要點擊的字數
            fontPos: [], // 選中的坐標信息
            checkPosArr: [], // 用戶點擊的坐標
            num: 1, // 點擊的記數
            pointBackImgBase: '', // 後端獲取到的背景圖片
            poinTextList: [], // 後端返回的點擊字體順序
            backToken: '', // 後端返回的token值
            setSize: {
                imgHeight: 0,
                imgWidth: 0,
                barHeight: 0,
                barWidth: 0
            },
            tempPoints: [],
            text: '',
            barAreaColor: undefined,
            barAreaBorderColor: undefined,
            showRefresh: true,
            bindingClick: true
        }
    },
    computed: {
        resetSize() {
            return resetSize
        }
    },
    methods: {
        init() {
            // 加載頁面
            this.fontPos.splice(0, this.fontPos.length)
            this.checkPosArr.splice(0, this.checkPosArr.length)
            this.num = 1
            this.getPictrue()
            this.$nextTick(() => {
                this.setSize = this.resetSize(this)	// 重新設置寬度高度
                this.$parent.$emit('ready', this)
            })
        },
        canvasClick(e) {
            this.checkPosArr.push(this.getMousePos(this.$refs.canvas, e))
            // eslint-disable-next-line eqeqeq
            if (this.num == this.checkNum) {
                this.num = this.createPoint(this.getMousePos(this.$refs.canvas, e))
                // 按比例轉換坐標值
                this.checkPosArr = this.pointTransfrom(this.checkPosArr, this.setSize)
                // 等創建坐標執行完
                setTimeout(() => {
                    // var flag = this.comparePos(this.fontPos, this.checkPosArr);
                    // 發送後端請求
                    var captchaVerification = this.secretKey ? aesEncrypt(this.backToken + '---' + JSON.stringify(this.checkPosArr), this.secretKey) : this.backToken + '---' + JSON.stringify(this.checkPosArr)
                    const data = {
                        captchaType: this.captchaType,
                        'pointJson': this.secretKey ? aesEncrypt(JSON.stringify(this.checkPosArr), this.secretKey) : JSON.stringify(this.checkPosArr),
                        'token': this.backToken
                    }
                    reqCheck(data).then(res => {
                        // eslint-disable-next-line eqeqeq
                        if (res.repCode == '0000') {
                            this.barAreaColor = '#4cae4c'
                            this.barAreaBorderColor = '#5cb85c'
                            this.text = '驗證成功'
                            this.bindingClick = false
                            // eslint-disable-next-line eqeqeq
                            if (this.mode == 'pop') {
                                setTimeout(() => {
                                    this.$parent.clickShow = false
                                    this.refresh()
                                }, 1500)
                            }
                            this.$parent.$emit('success', { captchaVerification })
                        } else {
                            this.$parent.$emit('error', this)
                            this.barAreaColor = '#d9534f'
                            this.barAreaBorderColor = '#d9534f'
                            this.text = '驗證失敗'
                            setTimeout(() => {
                                this.refresh()
                            }, 700)
                        }
                    })
                }, 400)
            }
            if (this.num < this.checkNum) {
                this.num = this.createPoint(this.getMousePos(this.$refs.canvas, e))
            }
        },

        // 獲取坐標
        getMousePos: function (obj, e) {
            var x = e.offsetX
            var y = e.offsetY
            return { x, y }
        },
        // 創建坐標點
        createPoint: function (pos) {
            this.tempPoints.push(Object.assign({}, pos))
            return ++this.num
        },
        refresh: function () {
            this.tempPoints.splice(0, this.tempPoints.length)
            this.barAreaColor = '#000'
            this.barAreaBorderColor = '#ddd'
            this.bindingClick = true
            this.fontPos.splice(0, this.fontPos.length)
            this.checkPosArr.splice(0, this.checkPosArr.length)
            this.num = 1
            this.getPictrue()
            this.text = '驗證失敗'
            this.showRefresh = true
        },

        // 請求背景圖片和驗證圖片
        getPictrue() {
            const data = {
                captchaType: this.captchaType
            }
            reqGet(data).then(res => {
                // eslint-disable-next-line eqeqeq
                if (res.repCode == '0000') {
                    this.pointBackImgBase = res.repData.originalImageBase64
                    this.backToken = res.repData.token
                    this.secretKey = res.repData.secretKey
                    this.poinTextList = res.repData.wordList
                    this.text = '請依次點擊【' + this.poinTextList.join(',') + '】'
                } else {
                    this.text = res.repMsg
                }
            })
        },
        // 坐標轉換函數
        pointTransfrom(pointArr, imgSize) {
            var newPointArr = pointArr.map(p => {
                const x = Math.round(310 * p.x / parseInt(imgSize.imgWidth))
                const y = Math.round(155 * p.y / parseInt(imgSize.imgHeight))
                return { x, y }
            })
            // console.log(newPointArr,"newPointArr");
            return newPointArr
        }
    },
    watch: {
        // type變化則全面刷新
        type: {
            immediate: true,
            handler() {
                this.init()
            }
        }
    },
    mounted() {
        // 禁止拖拽
        this.$el.onselectstart = function () {
            return false
        }
    }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * VerifySlide
 * @description 滑塊
 * */
import { aesEncrypt } from './../utils/ase'
import { resetSize } from './../utils/util'
import { reqGet, reqCheck } from '@/api/modular/system/loginManage'

//  "captchaType":"blockPuzzle",
export default {
    name: 'VerifySlide',
    props: {
        // eslint-disable-next-line vue/require-default-prop
        captchaType: {
            type: String
        },
        type: {
            type: String,
            default: '1'
        },
        // 彈出式pop，固定fixed
        mode: {
            type: String,
            default: 'fixed'
        },
        vSpace: {
            type: Number,
            default: 5
        },
        explain: {
            type: String,
            default: '向右滑動完成驗證'
        },
        imgSize: {
            type: Object,
            default() {
                return {
                    width: '310px',
                    height: '155px'
                }
            }
        },
        blockSize: {
            type: Object,
            default() {
                return {
                    width: '50px',
                    height: '50px'
                }
            }
        },
        barSize: {
            type: Object,
            default() {
                return {
                    width: '310px',
                    height: '40px'
                }
            }
        }
    },
    data() {
        return {
            secretKey: '', // 後端返回的加密秘鑰 字段
            passFlag: '', // 是否通過的標識
            backImgBase: '', // 驗證碼背景圖片
            blockBackImgBase: '', // 驗證滑塊的背景圖片
            backToken: '', // 後端返回的唯一token值
            startMoveTime: '', // 移動開始的時間
            endMovetime: '', // 移動結束的時間
            tipsBackColor: '', // 提示詞的背景顏色
            tipWords: '',
            text: '',
            finishText: '',
            setSize: {
                imgHeight: 0,
                imgWidth: 0,
                barHeight: 0,
                barWidth: 0
            },
            top: 0,
            left: 0,
            moveBlockLeft: undefined,
            leftBarWidth: undefined,
            // 移動中樣式
            moveBlockBackgroundColor: undefined,
            leftBarBorderColor: '#ddd',
            iconColor: undefined,
            iconClass: 'icon-right',
            status: false, // 鼠標狀態
            isEnd: false,		// 是夠驗證完成
            showRefresh: true,
            transitionLeft: '',
            transitionWidth: ''
        }
    },
    computed: {
        barArea() {
            return this.$el.querySelector('.verify-bar-area')
        },
        resetSize() {
            return resetSize
        }
    },
    methods: {
        init() {
            this.text = this.explain
            this.getPictrue()
            this.$nextTick(() => {
                const setSize = this.resetSize(this)	// 重新設置寬度高度
                for (const key in setSize) {
                    this.$set(this.setSize, key, setSize[key])
                }
                this.$parent.$emit('ready', this)
            })

            var _this = this

            window.removeEventListener('touchmove', function (e) {
                _this.move(e)
            })
            window.removeEventListener('mousemove', function (e) {
                _this.move(e)
            })

            // 鼠標松開
            window.removeEventListener('touchend', function () {
                _this.end()
            })
            window.removeEventListener('mouseup', function () {
                _this.end()
            })

            window.addEventListener('touchmove', function (e) {
                _this.move(e)
            })
            window.addEventListener('mousemove', function (e) {
                _this.move(e)
            })

            // 鼠標松開
            window.addEventListener('touchend', function () {
                _this.end()
            })
            window.addEventListener('mouseup', function () {
                _this.end()
            })
        },

        // 鼠標按下
        start: function (e) {
            e = e || window.event
            if (!e.touches) { // 兼容PC端
                var x = e.clientX
            } else { // 兼容移動端
                // eslint-disable-next-line no-redeclare
                var x = e.touches[0].pageX
            }
            this.startLeft = Math.floor(x - this.barArea.getBoundingClientRect().left)
            this.startMoveTime = +new Date() // 開始滑動的時間
            // eslint-disable-next-line eqeqeq
            if (this.isEnd == false) {
                this.text = ''
                this.moveBlockBackgroundColor = '#337ab7'
                this.leftBarBorderColor = '#337AB7'
                this.iconColor = '#fff'
                e.stopPropagation()
                this.status = true
            }
        },
        // 鼠標移動
        move: function (e) {
            e = e || window.event
            // eslint-disable-next-line eqeqeq
            if (this.status && this.isEnd == false) {
                if (!e.touches) { // 兼容PC端
                    var x = e.clientX
                } else { // 兼容移動端
                    // eslint-disable-next-line no-redeclare
                    var x = e.touches[0].pageX
                }
                // eslint-disable-next-line camelcase
                var bar_area_left = this.barArea.getBoundingClientRect().left
                // eslint-disable-next-line camelcase
                var move_block_left = x - bar_area_left // 小方塊相對於父元素的left值
                // eslint-disable-next-line camelcase
                if (move_block_left >= this.barArea.offsetWidth - parseInt(parseInt(this.blockSize.width) / 2) - 2) {
                    // eslint-disable-next-line camelcase
                    move_block_left = this.barArea.offsetWidth - parseInt(parseInt(this.blockSize.width) / 2) - 2
                }
                // eslint-disable-next-line camelcase
                if (move_block_left <= 0) {
                    // eslint-disable-next-line camelcase
                    move_block_left = parseInt(parseInt(this.blockSize.width) / 2)
                }
                // 拖動後小方塊的left值
                // eslint-disable-next-line camelcase
                this.moveBlockLeft = (move_block_left - this.startLeft) + 'px'
                // eslint-disable-next-line camelcase
                this.leftBarWidth = (move_block_left - this.startLeft) + 'px'
            }
        },

        // 鼠標松開
        end: function () {
            this.endMovetime = +new Date()
            var _this = this
            // 判斷是否重合
            // eslint-disable-next-line eqeqeq
            if (this.status && this.isEnd == false) {
                var moveLeftDistance = parseInt((this.moveBlockLeft || '').replace('px', ''))
                moveLeftDistance = moveLeftDistance * 310 / parseInt(this.setSize.imgWidth)
                const data = {
                    captchaType: this.captchaType,
                    'pointJson': this.secretKey ? aesEncrypt(JSON.stringify({ x: moveLeftDistance, y: 5.0 }), this.secretKey) : JSON.stringify({ x: moveLeftDistance, y: 5.0 }),
                    'token': this.backToken
                }
                reqCheck(data).then(res => {
                    // eslint-disable-next-line eqeqeq
                    if (res.repCode == '0000') {
                        this.moveBlockBackgroundColor = '#5cb85c'
                        this.leftBarBorderColor = '#5cb85c'
                        this.iconColor = '#fff'
                        this.iconClass = 'icon-check'
                        this.showRefresh = false
                        this.isEnd = true
                        // eslint-disable-next-line eqeqeq
                        if (this.mode == 'pop') {
                            setTimeout(() => {
                                this.$parent.clickShow = false
                                this.refresh()
                            }, 1500)
                        }
                        this.passFlag = true
                        this.tipWords = `${((this.endMovetime - this.startMoveTime) / 1000).toFixed(2)}s驗證成功`
                        var captchaVerification = this.secretKey ? aesEncrypt(this.backToken + '---' + JSON.stringify({ x: moveLeftDistance, y: 5.0 }), this.secretKey) : this.backToken + '---' + JSON.stringify({ x: moveLeftDistance, y: 5.0 })
                        setTimeout(() => {
                            this.tipWords = ''
                            this.$parent.closeBox()
                            this.$parent.$emit('success', { captchaVerification })
                        }, 1000)
                    } else {
                        this.moveBlockBackgroundColor = '#d9534f'
                        this.leftBarBorderColor = '#d9534f'
                        this.iconColor = '#fff'
                        this.iconClass = 'icon-close'
                        this.passFlag = false
                        setTimeout(function () {
                            _this.refresh()
                        }, 1000)
                        this.$parent.$emit('error', this)
                        this.tipWords = '驗證失敗'
                        setTimeout(() => {
                                this.tipWords = ''
                        }, 1000)
                    }
                })
                this.status = false
            }
        },

        refresh: function () {
            this.showRefresh = true
            this.finishText = ''

            this.transitionLeft = 'left .3s'
            this.moveBlockLeft = 0

            this.leftBarWidth = undefined
            this.transitionWidth = 'width .3s'

            this.leftBarBorderColor = '#ddd'
            this.moveBlockBackgroundColor = '#fff'
            this.iconColor = '#000'
            this.iconClass = 'icon-right'
            this.isEnd = false

            this.getPictrue()
            setTimeout(() => {
                this.transitionWidth = ''
                this.transitionLeft = ''
                this.text = this.explain
            }, 300)
        },

        // 請求背景圖片和驗證圖片
        getPictrue() {
            const data = {
                captchaType: this.captchaType
            }
            reqGet(data).then(res => {
                // eslint-disable-next-line eqeqeq
                if (res.repCode == '0000') {
                    this.backImgBase = res.repData.originalImageBase64
                    this.blockBackImgBase = res.repData.jigsawImageBase64
                    this.backToken = res.repData.token
                    this.secretKey = res.repData.secretKey
                } else {
                    this.tipWords = res.repMsg
                }
            })
        }
    },
    watch: {
        // type變化則全面刷新
        type: {
            immediate: true,
            handler() {
                this.init()
            }
        }
    },
    mounted() {
        // 禁止拖拽
        this.$el.onselectstart = function () {
            return false
        }
    }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * Verify 驗證碼組件
 * @description 分發驗證碼使用
 * */
import VerifySlide from './Verify/VerifySlide'
import VerifyPoints from './Verify/VerifyPoints'

export default {
    name: 'Vue2Verify',
    props: {
        // 雙語化
        locale: {
            require: false,
            type: String,
            default() {
                // 默認語言不輸入為瀏覽器語言
                if (navigator.language) {
                    var language = navigator.language
                } else {
                    // eslint-disable-next-line no-redeclare
                    var language = navigator.browserLanguage
                }
                return language
            }
        },
        captchaType: {
            type: String,
            required: true
        },
        // eslint-disable-next-line vue/require-default-prop
        figure: {
            type: Number
        },
        // eslint-disable-next-line vue/require-default-prop
        arith: {
            type: Number
        },
        mode: {
            type: String,
            default: 'pop'
        },
        // eslint-disable-next-line vue/require-default-prop
        vSpace: {
            type: Number
        },
        // eslint-disable-next-line vue/require-default-prop
        explain: {
            type: String
        },
        imgSize: {
            type: Object,
            default() {
                return {
                    width: '310px',
                    height: '155px'
                }
            }
        },
        // eslint-disable-next-line vue/require-default-prop
        blockSize: {
            type: Object
        },
        // eslint-disable-next-line vue/require-default-prop
        barSize: {
            type: Object
        }
    },
    data() {
        return {
            // showBox:true,
            clickShow: false,
            // 內部類型
            verifyType: undefined,
            // 所用組件類型
            componentType: undefined
        }
    },
    methods: {
        /**
         * i18n
         * @description 兼容vue-i18n 調用$t來轉換ok
         * @param {String} text-被轉換的目標
         * @return {String} i18n的結果
         * */
        i18n(text) {
            if (this.$t) {
                return this.$t(text)
            } else {
                // 兼容不存在的語言
                const i18n = this.$options.i18n.messages[this.locale] || this.$options.i18n.messages['en-US']
                return i18n[text]
            }
        },
        /**
         * refresh
         * @description 刷新
         * */
        refresh() {
            if (this.instance.refresh) {
                this.instance.refresh()
            }
        },
        closeBox() {
            this.clickShow = false
            this.refresh()
        },
        show() {
            // eslint-disable-next-line eqeqeq
            if (this.mode == 'pop') {
                this.clickShow = true
            }
        }
    },
    computed: {
        instance() {
            return this.$refs.instance || {}
        },
        showBox() {
            // eslint-disable-next-line eqeqeq
            if (this.mode == 'pop') {
                return this.clickShow
            } else {
                return true
            }
        }
    },
    watch: {
        captchaType: {
            immediate: true,
            handler(captchaType) {
                switch (captchaType.toString()) {
                    case 'blockPuzzle':
                        this.verifyType = '2'
                        this.componentType = 'VerifySlide'
                        break
                    case 'clickWord':
                        this.verifyType = ''
                        this.componentType = 'VerifyPoints'
                        break
                }
            }
        }
    },
    components: {
        VerifySlide,
        VerifyPoints
    }
}
